'use strict';

// stylesheet import
import '../sass/main.sass';
// import '../scss/main.scss';

import Vue from 'vue';
// import App from './vue/app.vue';
// import { IntersectionObserverToggleClass } from './modules/class-intersection-observer-toggleclass.js';
// import { IsLegacyIE } from './modules/checking-legacy-ie.js';
// import { FindOS } from './modules/checking-os.js';
// import { FindBrowser } from './modules/checking-browser.js';
const $ = require('jQuery');

// Vue.js
// new Vue(App).$mount('#app');

// jQuery
$(function() {
  
  // accordion panel
  $('.js-accordion-panel-trigger').each(function() {
    $(this).on('click', function() {
      $(this).next('.js-accordion-panel-element').slideToggle();
    })
  });

  // scroll top
  $('.js-scroll-top').on('click', function() {
    $('html, body').animate({scrollTop:0});
  });

  // modal
  $('.js-modal-open').each(function() {
    $(this).on('click', function() {
      const imageSrc = $(this).attr('src');
      $('.js-modal').fadeIn().css('display','flex');
      $('.js-modal-image').attr('src', imageSrc);
    });
  });
  $('.js-modal-close').on('click', function() {
    $('.js-modal').fadeOut();
  });

  // dialog
  const dialog = document.querySelector('.js-dialog');
  $('.js-dialog-open').on('click', function() {
    const image = $(this).attr('data-image');
    const description = $(this).attr('data-description');
    const title = $(this).attr('data-title');
    $('.js-dialog-image').attr('src', image);
    $('.js-dialog-description').html(description);
    $('.js-dialog-title').text(title);
    dialog.showModal();
  });
  $('.js-dialog-close').on('click', function() {
    dialog.close();
    $('.js-dialog-image').attr('src', '');
    $('.js-dialog-description').html('');
    $('.js-dialog-title').text('');
  });

});

// Intersection Observer
// new IntersectionObserverToggleClass({
//   selector: '.observe_target', 
//   className: 'observed', 
//   toggle: true
// });

// checking legacy IE
// IsLegacyIE();

// checking OS
// FindOS();

// checking browser
// FindBrowser();